<template>
  <v-btn v-bind="$attrs" @click="handleDownloadClick">{{ $t('orders.actions.download') }}</v-btn>
</template>

<script>
import { download } from '@/modules/orders/api/order.js';
import downloadFile from '@/application/util/downloadFile.js';

export default {
  name: 'DownloadOrderButton',
  props: {
    orderId: {
      required: true,
      type: Number,
    },
    dealerCode: {
      required: true,
      type: String,
    },
    dealerCodeExtension: {
      type: String,
    },
    dealerName: {
      required: true,
      type: String,
    }
  },
  methods: {
    handleDownloadClick() {
      const fileName = this.dealerCodeExtension != null
        ? `${this.dealerCode}-${this.dealerCodeExtension}.${this.dealerName}.${this.orderId}.pdf`
        : `${this.dealerCode}.${this.dealerName}.${this.orderId}.pdf`;
      downloadFile(download(this.orderId), fileName);
    },
  },
};
</script>
