<template>
  <k-sidebar v-bind="$attrs"
             v-on="$listeners"
             fixed
             right
             temporary
             width="600"
             :with-save-button="false">
    <v-row no-gutters justify="center">
      <v-col cols="12" class="comments-timeline-wrapper" :class="{'small-timeline' : ableToPlaceComments}">
        <v-timeline class="comments-timeline" :class="{'small-timeline' : ableToPlaceComments}">
          <OrderCommentTimelineItem v-for="(comment, index) in comments"
                                    :comment="comment"
                                    :key="index"
                                    :is-sender="getCurrentUserId === comment.userId"
                                    :is-date-visible="hasDateChip(index)"/>
        </v-timeline>

        <v-row justify="center" no-gutters>
          <v-btn text
                 @click="loadMore"
                 :loading="loadingMore"
                 :disabled="page >= lastPage"
                 class="mr-4 mt-2"
                 color="primary">
            {{ $t('actions.loadMore') }}
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="ableToPlaceComments">
        <VTextarea v-model="message" :label="`${$t('orders.fields.comment')}:`"/>
      </v-col>
    </v-row>

    <template #actions>
      <v-card-actions>
        <VSpacer/>
        <v-btn v-if="ableToPlaceComments"
               color="primary"
               depressed
               tile
               @click="handleCreateMessage"
               :loading="loading">
          {{ $t('orders.fields.comment') }}
        </v-btn>
      </v-card-actions>
    </template>
  </k-sidebar>
</template>

<script>
import KSidebar from '@/components/layout/KSidebar.vue';
import OrderCommentTimelineItem from '@/modules/orders/components/OrderCommentTimelineItem.vue';
import { comments, create } from '@/modules/orders/api/comment.js';
import OrderComment from '@/application/models/Comment.js';
import { mapGetters } from 'vuex';
import dayjs from '@/plugins/dayjs.js';

export default {
  name: 'OrderCommentsSideBar',
  components: {
    OrderCommentTimelineItem,
    KSidebar,
  },
  mounted() {
    this.$nextTick(() => {
      this.getComments();
    });
  },
  props: {
    ableToPlaceComments: {
      type: Boolean,
      default: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      comments: [],
      loading: false,
      loadingMore: false,
      message: '',
      page: 1,
      lastPage: 1,
      perPage: 15,
    };
  },
  computed: {
    ...mapGetters('authorisation', ['getCurrentUserId', 'username']),
  },
  methods: {
    async getComments() {
      this.page = 1;
      const result = await this.getPaginator();
      this.comments = [...result];
    },
    hasDateChip(index) {
      return index === 0 || this.comments[index - 1].date !== this.comments[index].date;
    },
    async handleCreateMessage() {
      this.loading = true;
      await create(this.orderId, this.message);

      this.comments.unshift(new OrderComment().mapResponse({
        id: 0,
        comment: this.message,
        userName: this.username,
        userId: this.getCurrentUserId,
        createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      }));

      this.loading = false;
      this.message = '';
    },
    async loadMore() {
      this.page++;
      const result = await this.getPaginator();
      this.comments = [...this.comments, ...result];
    },
    async getPaginator() {
      this.loadingMore = true;

      const response = await comments(this.orderId, this.page, this.perPage, this.searchParameter);
      this.lastPage = response.data.meta.lastPage;
      this.loadingMore = false;

      return response.data.data.map(comment => new OrderComment().mapResponse(comment));
    },
  },
};
</script>

<style scoped lang="scss">
.comments-timeline-wrapper {
  overflow-y: auto;
  max-height: 95vh;

  .comments-timeline {
    min-height: 90vh;

    &.small-timeline {
      min-height: 68vh;
    }
  }

  &.small-timeline {
    max-height: 73vh;
  }
}
</style>
